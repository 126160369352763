<script>
export default {
  name: "PageLoader",
};
</script>
<template>
  <div id="Loader">
    <div class="loader-container">
      <h1>DL</h1>
      <div class="loader-bar" />
    </div>
  </div>
</template>
<style lang="scss">
#Loader {
  background-color: $app_dark_background;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .loader-container {
    color: $white;
    @include bowlby-one-font;
    letter-spacing: 0.2em;
    font-size: 1.2em;
    width: 80px;
    h1 {
      text-align: center;
      animation: 1.5s ease-out 0s 1 loaderText;
      width: 100%;
      padding: none;
      margin: 5px;
      opacity: 0;
    }
    .loader-bar {
      width: 100%;
      height: 2px;
      background-color: $highlight;
      animation: 1.5s ease-out 0s 1 loaderBar;
      opacity: 0;
    }
  }
}
</style>
