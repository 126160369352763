<script>
import { contentMixin } from "@/mixins/contentMixin.js";
import { DomUtility } from "@/utilities/domUtility";

export default {
  name: "MobileMenu",
  mixins: [contentMixin],
  methods: {
    hideMobileMenu() {
      DomUtility.hideMobileMenu();
    },
    goToMenuItem(event, itemLink) {
      DomUtility.goToMenuItem(itemLink);
    },
  },
};
</script>
<template>
  <div id="Menu" class="dark">
    <div class="menu-container">
      <div class="close-button" @click="hideMobileMenu">
        <img src="@/assets/images/icons8-close-50.png" alt="close" />
      </div>
      <div class="menu-items-container">
        <div
          v-bind:key="item.id"
          v-for="item in content.items"
          class="menu-item"
          @click="($event) => goToMenuItem($event, item.href)"
        >
          <p v-if="item.label">{{ item.label }}</p>
          <img
            v-else
            src="@/assets/images/icons8-home-30.png"
            alt="home icon"
          />
        </div>
        <div class="social-icons">
          <a v-bind:href="content.links.github" target="_blank">
            <img
              class="icon"
              src="@/assets/images/icons8-github-30.png"
              alt="github"
            />
          </a>
          <a v-bind:href="content.links.linkedIn" target="_blank">
            <img
              class="icon"
              src="@/assets/images/icons8-linkedin-2-50.png"
              alt="linkedin"
            />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
#Menu {
  width: 0px;
  height: 100vh;
  position: fixed;
  @include exo;
  top: 0;
  right: 0;
  z-index: 2;
  background-color: $app_background;
  .menu-container {
    width: 100%;
    height: 100%;
    position: relative;
    box-shadow: none;
    display: none;
    .close-button {
      position: absolute;
      margin: 15px;
      top: 0;
      right: 0;
      img {
        width: 30px;
        height: auto;
        &:hover {
          @include highlight-filter;
          translate: 0px -5px;
        }
      }
    }
    .menu-items-container {
      width: max-content;
      height: max-content;
      margin: auto;
      text-align: center;
      color: $charcoal;
      @include wgt-500;
      .menu-item {
        padding: 10px 0px;
        p {
          &:hover {
            color: $highlight;
            translate: 0px -5px;
          }
        }
        img {
          &:hover {
            @include highlight-filter;
            translate: 0px -5px;
          }
        }
      }
      .social-icons {
        .icon {
          width: 25px;
          height: auto;
          margin: 0px 7px;
          &:hover {
            @include highlight-filter;
            translate: 0px -5px;
          }
        }
      }
    }
  }
  &.active {
    .menu-container {
      display: flex;
    }
    width: 200px;
    box-shadow: -10px 0px 30px -15px $grey;
    @include xs {
      width: 250px;
    }
    @include sm {
      width: 270px;
    }
    @include md {
      width: 300px;
    }
  }
  &.dark {
    background-color: $app_dark_background;
    box-shadow: -10px 0px 30px -15px $charcoal;
    .menu-container {
      .close-button {
        img {
          @include white-filter;
          &:hover {
            @include highlight-filter;
            translate: 0px -5px;
          }
        }
      }
      .menu-items-container {
        color: $xlight_grey;
        .menu-item {
          p {
            &:hover {
              color: $highlight;
            }
          }
          img {
            @include white-filter;
            &:hover {
              @include highlight-filter;
            }
          }
        }
        .social-icons {
          .icon {
            @include white-filter;
            &:hover {
              @include highlight-filter;
            }
          }
        }
      }
    }
  }
}
</style>
