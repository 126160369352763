import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { createStore } from "vuex";

const store = createStore({
  state() {
    return {
      content: {},
      darkMode: true,
      isMobileView: null,
      isLoading: true,
    };
  },
  mutations: {
    setContent(state, content) {
      state.content = content;
    },
    setMobileView(state, value) {
      state.isMobileView = value;
    },
    setIsLoading(state, value) {
      state.isLoading = value;
    },
    setDarkMode(state) {
      state.darkMode = !state.darkMode;
    },
  },
  getters: {
    content(state) {
      return state.content;
    },
    isMobileView(state) {
      return state.isMobileView;
    },
    isLoading(state) {
      return state.isLoading;
    },
    darkMode(state) {
      return state.darkMode;
    },
  },
});

createApp(App).use(store).use(router).mount("#app");
