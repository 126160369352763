<script>
import PageLoader from "@/Components/PageLoader.vue";
import TopPanel from "@/Components/TopPanel.vue";
import MobileMenu from "@/Components/MobileMenu.vue";
import { DomUtility } from "@/utilities/domUtility";
import { mapMutations, mapGetters } from "vuex";
import { isEmpty } from "lodash";

export default {
  name: "App",
  components: {
    PageLoader,
    TopPanel,
    MobileMenu,
  },
  data() {
    return {
      error: null,
      screenWidth: 0,
    };
  },
  mounted() {
    this.updateScreenWidth();
    this.onScreenResize();
    this.setMobileView(this.screenWidth < 1020);
    this.onScroll();
  },
  created() {
    // Fetch content json from public/content.json
    fetch("./content.json")
      .then((res) => res.json())
      .then(
        (json) => {
          setTimeout(() => {
            this.setIsLoading(false);
            this.setContent(json);
          }, 2000);
        },
        (error) => {
          this.error = error;
        }
      );
  },
  computed: {
    ...mapGetters(["content", "isLoading"]),
    hasContent() {
      return !isEmpty(this.content);
    },
    isLoaded() {
      return this.hasContent && !this.isLoading;
    },
  },
  methods: {
    ...mapMutations(["setContent", "setMobileView", "setIsLoading"]),
    onScreenResize() {
      window.addEventListener("resize", () => {
        this.updateScreenWidth();
        this.setMobileView(this.screenWidth < 1020);
        if (this.screenWidth >= 1020) {
          DomUtility.hideMobileMenu();
        }
      });
    },
    onScroll() {
      window.addEventListener("scroll", () => {
        DomUtility.onScroll();
      });
    },
    updateScreenWidth() {
      this.screenWidth = window.innerWidth;
    },
  },
};
</script>

<template>
  <div id="App" class="dark">
    <PageLoader v-if="!isLoaded" />
    <div v-else>
      <TopPanel :content="content.topPanel" />
      <MobileMenu :content="content.menu" />
      <router-view />
    </div>
  </div>
</template>

<style lang="scss">
html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  background-color: $app_background;
  &.dark {
    background-color: $app_dark_background;
  }
  &.mobile-menu-active {
    overflow: hidden;
  }
}

#App {
  background-color: $app_background;
  width: 100%;
  min-height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  * {
    overflow-wrap: break-word;
    transition: 0.3s, color 0s, background-color 0s;
  }
  font-size: 11pt;
  @include md {
    font-size: 11.5pt;
  }
  @include lg {
    font-size: 12pt;
  }
  @include xl {
    font-size: 12.5pt;
  }
  &.dark {
    background-color: $app_dark_background;
  }
}
</style>
