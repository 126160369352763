<script>
import ProjectItem from "@/Components/ProjectItem.vue";
import { contentMixin } from "@/mixins/contentMixin.js";

export default {
  name: "ProjectsSection",
  mixins: [contentMixin],
  components: {
    ProjectItem,
  },
};
</script>
<template>
  <div class="section dark" id="Projects">
    <div class="section-container">
      <div class="projects-container show-on-scroll animate-on-mobile">
        <p class="section-header show-on-scroll" v-html="content.header" />
        <ProjectItem
          v-bind:key="index"
          v-for="(project, index) in content.projects"
          :content="project"
        />
      </div>
    </div>
  </div>
</template>
<style lang="scss">
#Projects {
  .section-container {
    .projects-container {
      margin: 15px;
      color: $charcoal;
      @include lg {
        margin: auto;
      }
    }
  }
}
</style>
