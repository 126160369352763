<script>
import { contentMixin } from "@/mixins/contentMixin.js";
import { DomUtility } from "@/utilities/domUtility";
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "TopPanel",
  mixins: [contentMixin],
  computed: {
    ...mapGetters(["isMobileView"]),
  },
  methods: {
    ...mapMutations(["setDarkMode"]),
    showMobileMenu() {
      DomUtility.showMobileMenu();
    },
    toggleDarkMode() {
      this.setDarkMode();
      DomUtility.toggleDarkMode();
    },
  },
};
</script>
<template>
  <div id="TopPanel" class="dark">
    <div class="float-left">
      <a v-bind:href="content.links.home">
        <img class="logo" src="@/assets/images/logo.png" alt="logo" />
      </a>
    </div>
    <div class="float-right">
      <div class="icons">
        <div class="dark-mode-button" @click="toggleDarkMode">
          <img
            class="moon icon"
            src="@/assets/images/icons8-moon-and-stars-50.png"
            alt="moon"
          />
          <img
            class="sun icon"
            src="@/assets/images/icons8-sun-50.png"
            alt="sun"
          />
        </div>
        <div v-if="!isMobileView" class="social-icons">
          <a v-bind:href="content.links.github" target="_blank">
            <img
              class="icon"
              src="@/assets/images/icons8-github-30.png"
              alt="github"
            />
          </a>
          <a v-bind:href="content.links.linkedIn" target="_blank">
            <img
              class="icon"
              src="@/assets/images/icons8-linkedin-2-50.png"
              alt="linkedin"
            />
          </a>
        </div>
        <div v-if="isMobileView" class="menu-button" @click="showMobileMenu">
          <img
            class="icon"
            src="@/assets/images/icons8-menu-rounded-50.png"
            alt="menu"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
#TopPanel {
  width: 100%;
  height: 60px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: $app_background;
  opacity: 0.9;
  animation: 1s ease-out 0s 1 dropDown;
  @include exo;
  .float-left {
    float: left;
    width: fit-content;
    height: 100%;
    margin: 0 10px;
    display: flex;
    a {
      height: fit-content;
      margin: auto;
    }
    img.logo {
      width: 40px;
      height: auto;
      filter: none;
    }
  }
  .float-right {
    float: right;
    width: fit-content;
    height: 100%;
    margin: 0 10px;
    display: flex;
    .icons {
      height: fit-content;
      margin: auto;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      .icon {
        width: 25px;
        height: auto;
        &:hover {
          @include highlight-filter;
          translate: 0px -5px;
        }
      }
      .dark-mode-button {
        width: 30px;
        position: relative;
        .sun {
          position: absolute;
          top: 0;
          opacity: 0;
          visibility: hidden;
        }
        .moon {
          position: absolute;
          top: 0;
        }
      }
      .social-icons {
        .icon {
          margin: 0px 7px;
        }
      }
      .dark-mode-button,
      .menu-button {
        margin: 0px 7px;
      }
    }
  }
  &.dark {
    background-color: $app_dark_background;
    .float-left {
      img.logo {
        @include white-filter;
      }
    }
    .float-right {
      .icons {
        .icon {
          @include white-filter;
          &:hover {
            @include highlight-filter;
          }
        }
        .dark-mode-button {
          .sun {
            opacity: 100;
            visibility: visible;
          }
          .moon {
            opacity: 0;
            visibility: hidden;
          }
        }
      }
    }
  }
  &.mobile-menu-active {
    @include blur-backdrop;
  }
}
</style>
