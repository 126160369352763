<script>
import HomeSection from "@/Components/HomeSection.vue";
import AboutSection from "@/Components/AboutSection.vue";
import ProjectsSection from "@/Components/ProjectsSection.vue";
import DesignSection from "./DesignSection.vue";
import { contentMixin } from "@/mixins/contentMixin.js";
export default {
  name: "PortfolioContainer",
  mixins: [contentMixin],
  components: {
    HomeSection,
    AboutSection,
    ProjectsSection,
    DesignSection,
  },
};
</script>
<template>
  <div id="Portfolio" class="dark">
    <div class="last-updated">
      <img src="@/assets/images/icons8-info-squared-50.png" alt="info icon" />
      <span v-html="content.lastUpdate" />
    </div>
    <HomeSection :content="content.home" />
    <AboutSection :content="content.about" />
    <ProjectsSection :content="content.projects" />
    <DesignSection :content="content.designs" />
    <div class="footer">
      <p v-html="content.footer.text" />
      <a v-bind:href="content.footer.iconsEight.link" target="_blank">
        <p v-html="content.footer.iconsEight.label" />
      </a>
    </div>
  </div>
</template>
<style lang="scss">
#Portfolio {
  margin-left: 0;
  @include exo;
  height: max-content;
  min-height: 100vh;
  .last-updated {
    display: none;
    position: fixed;
    bottom: 20px;
    right: 20px;
    animation: 1s ease-out 0s 1 slideAndTilt;
    @include popover;
    img {
      width: 30px;
      height: 30px;
    }
    @include xl {
      display: block;
    }
    &:hover {
      img {
        @include highlight-filter;
      }
    }
  }
  .section {
    width: 100%;
    display: flex;
    min-height: auto;
    height: fit-content;
    @include exo;
    .section-container {
      margin: 60px auto;
      color: $charcoal;
      .section-header {
        width: fit-content;
        font-size: 1.3em;
        @include exo;
        @include wgt-600;
        color: $subtext;
        padding: 10px 15px;
        border: 2px solid $charcoal;
        box-shadow: -5px -5px $highlight, -5px -5px 0em 0.1em $charcoal;
      }
      @include md {
        width: 90%;
      }
      @include lg {
        width: 800px;
        margin: auto;
      }
      @include xl {
        width: 1000px;
      }
    }
    &:not(:first-child) {
      padding-top: 0px;
      box-sizing: border-box;
      @include lg {
        padding-top: 60px;
      }
    }
    @include lg {
      min-height: 100vh;
    }
  }
  @include lg {
    margin-left: 200px;
  }
  .footer {
    width: 100%;
    text-align: center;
    font-size: 0.9em;
    color: $highlight;
    @include p-link;
  }
  &.dark {
    .last-updated {
      img {
        @include white-filter;
      }
      &:hover {
        img {
          @include highlight-filter;
        }
      }
      span {
        background-color: rgba(255, 255, 255, 0.9);
        color: $app_dark_background;
        &:after {
          border-left: solid 10px rgba(255, 255, 255, 0.9);
        }
      }
    }
    .section {
      .section-header {
        color: $xlight_grey;
        border-color: $xlight_grey;
        border-radius: 5px;
        box-shadow: -5px -5px $highlight;
      }
    }
  }
  &.mobile-menu-active {
    @include blur-backdrop;
  }
}
</style>
