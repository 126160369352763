class domUtility {
  constructor() {}

  hasClass(elementId, className) {
    return document.getElementById(elementId)?.classList.contains(className);
  }

  addClass(elementId, className) {
    document.getElementById(elementId)?.classList.add(className);
  }

  removeClass(elementId, className) {
    document.getElementById(elementId)?.classList.remove(className);
  }

  showMobileMenu() {
    this.addClass("Menu", "active");
    ["TopPanel", "Portfolio"].forEach((element) =>
      this.addClass(element, "mobile-menu-active")
    );
    document.body.classList.add("mobile-menu-active");
  }

  hideMobileMenu() {
    this.removeClass("Menu", "active");
    ["TopPanel", "Portfolio"].forEach((element) =>
      this.removeClass(element, "mobile-menu-active")
    );
    document.body.classList.remove("mobile-menu-active");
  }

  get isMobileMenuActive() {
    return document.getElementById("Menu").classList.contains("active");
  }

  goToMenuItem(link) {
    this.hideMobileMenu();
    window.location = link;
  }

  get isDarkModeOn() {
    return document.getElementById("App").classList.contains("dark");
  }

  darkModeOn() {
    [
      "App",
      "PortfolioView",
      "TopPanel",
      "SidePanel",
      "Menu",
      "Portfolio",
      "Home",
      "About",
      "Projects",
      "Archive",
    ].forEach((element) => this.addClass(element, "dark"));
    const projectCollection = document.getElementsByClassName("project");
    for (let i = 0; i < projectCollection.length; i++) {
      let element = projectCollection[i];
      if (!element.classList.contains("dark")) {
        element.classList.add("dark");
      }
    }
    const designCollection = document.getElementsByClassName("design");
    for (let i = 0; i < designCollection.length; i++) {
      let element = designCollection[i];
      if (!element.classList.contains("dark")) {
        element.classList.add("dark");
      }
    }
    document.body.classList.add("dark");
  }

  darkModeOff() {
    [
      "App",
      "PortfolioView",
      "TopPanel",
      "SidePanel",
      "Menu",
      "Portfolio",
      "Home",
      "About",
      "Projects",
      "Archive",
    ].forEach((element) => this.removeClass(element, "dark"));
    const projectCollection = document.getElementsByClassName("project");
    for (let i = 0; i < projectCollection.length; i++) {
      let element = projectCollection[i];
      element.classList.remove("dark");
    }
    const designCollection = document.getElementsByClassName("design");
    for (let i = 0; i < designCollection.length; i++) {
      let element = designCollection[i];
      element.classList.remove("dark");
    }
    document.body.classList.remove("dark");
  }

  toggleDarkMode() {
    if (this.isDarkModeOn) {
      this.darkModeOff();
    } else {
      this.darkModeOn();
    }
  }

  toggleCollapsible(labelId, elementId, cotainerId, margin) {
    let container = document.getElementById(cotainerId);
    let element = document.getElementById(elementId);

    let offset = element.offsetHeight;
    if (margin) {
      offset += 2 * margin;
    }
    if (container.classList.contains("collapsed")) {
      container.classList.remove("collapsed");
      container.style.height = offset.toString() + "px";
      this.removeClass(labelId, "collapsed");
    } else {
      container.classList.add("collapsed");
      container.style.height = "0px";
      this.addClass(labelId, "collapsed");
    }
  }

  setNavItem(navId) {
    if (!this.hasClass(navId, "active")) {
      const collection = document.getElementsByClassName("side-panel-item");
      for (let i = 0; i < collection.length; i++) {
        let element = collection[i];
        if (element.id === navId) {
          this.addClass(element.id, "active");
        } else {
          this.removeClass(element.id, "active");
        }
      }
    }
  }

  getTopOffset(element) {
    var box = element.getBoundingClientRect();

    var body = document.body;
    var docEl = document.documentElement;

    var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;

    var clientTop = docEl.clientTop || body.clientTop || 0;

    var top = box.top + scrollTop - clientTop;

    return Math.round(top);
  }

  getBottomOffset(element) {
    var box = element.getBoundingClientRect();

    var body = document.body;
    var docEl = document.documentElement;

    var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;

    var clientTop = docEl.clientTop || body.clientTop || 0;

    var top = box.bottom;

    return Math.round(top) + scrollTop - clientTop;
  }

  isElementInViewport(el) {
    var rect = el.getBoundingClientRect();
    return (
      (rect.top <= 0 && rect.bottom >= 0) ||
      (rect.bottom >=
        (window.innerHeight || document.documentElement.clientHeight) &&
        rect.top <=
          (window.innerHeight || document.documentElement.clientHeight)) ||
      (rect.top >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight))
    );
  }

  onScroll() {
    const portfolioCollection = document.getElementsByClassName("section");
    if (window.innerWidth >= 1020) {
      const elementsToShow = document.querySelectorAll(".show-on-scroll");
      for (let i = 0; i < elementsToShow.length; i++) {
        let element = elementsToShow[i];
        if (
          this.isElementInViewport(element) &&
          !element.classList.contains("is-visible")
        ) {
          element.classList.add("is-visible");
        }
      }
      for (let i = 0; i < portfolioCollection.length; i++) {
        let element = portfolioCollection[i];
        if (
          Math.abs(window.pageYOffset - this.getTopOffset(element)) <= 500 ||
          window.pageYOffset - this.getBottomOffset(element) < -600
        ) {
          this.setNavItem(element.id + "NavItem");
          break;
        }
      }
    }
  }
}

export const DomUtility = new domUtility();
