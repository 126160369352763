<script>
import { contentMixin } from "@/mixins/contentMixin.js";

export default {
  name: "ProjectItem",
  mixins: [contentMixin],
};
</script>
<template>
  <div class="project dark">
    <div class="text-container">
      <p class="header show-on-scroll" v-html="content.title" />
      <div v-if="content.links" class="links show-on-scroll">
        <a
          v-if="content.links.github"
          v-bind:href="content.links.github"
          target="_blank"
        >
          <img
            class="icon"
            src="@/assets/images/icons8-github-30.png"
            alt="github"
          />
        </a>
        <a
          v-if="content.links.projectUrl"
          v-bind:href="content.links.projectUrl"
          target="_blank"
        >
          <img
            class="icon"
            src="@/assets/images/icons8-external-link-30.png"
            alt="external link"
          />
        </a>
      </div>
      <div class="tools-container show-on-scroll">
        <div
          v-bind:key="index"
          v-for="(tool, index) in content.tools"
          class="tool"
        >
          <p>{{ tool }}</p>
        </div>
      </div>
      <div class="description show-on-scroll">
        <p v-html="content.description" />
      </div>
    </div>
    <div v-if="content.img" class="image-container show-on-scroll">
      <a v-bind:href="content.links.projectUrl" target="_blank">
        <img
          v-bind:src="require(`@/${content.img}`)"
          alt="project screenshot"
        />
      </a>
    </div>
  </div>
</template>
<style lang="scss">
.project {
  margin: 40px auto 100px auto;
  @include exo;
  .text-container {
    .header {
      font-size: 1.3em;
      @include wgt-600;
      color: $charcoal;
    }
    .links {
      .icon {
        width: 25px;
        height: auto;
        margin-right: 10px;
        &:hover {
          @include highlight-filter;
          translate: 0px -5px;
        }
      }
    }
    .tools-container {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      .tool {
        margin-right: 10px;
        border-radius: 5px;
        p {
          color: $highlight;
          @include exo;
        }
      }
    }
    .description {
      line-height: 40px;
      @include p-link;
      @include wgt-300;
      color: $charcoal;
    }
  }
  .image-container {
    width: 100%;
    display: flex;
    margin-top: 30px;
    a {
      width: 95%;
      margin: auto;
      img {
        width: 100%;
        height: auto;
        @include image-hover-light;
        border: 2px solid $charcoal;
        box-shadow: 5px 5px $highlight, 5px 5px 0em 0.1em $charcoal;
      }
    }
  }
  &.dark {
    .text-container {
      .header {
        color: $xlight_grey;
      }
      .links {
        .icon {
          @include white-filter;
          &:hover {
            @include highlight-filter;
          }
        }
      }
      .tools-container {
        .tool {
          p {
            color: $highlight;
          }
        }
      }
      .description {
        color: $xlight_grey;
      }
    }
    .image-container {
      a img {
        border-radius: 5px;
        @include image-hover-dark;
      }
    }
  }
}
</style>
