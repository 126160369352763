<script>
import { contentMixin } from "@/mixins/contentMixin.js";

export default {
  name: "DesignItem",
  mixins: [contentMixin],
  props: {
    index: {
      type: Number,
      default: null,
    },
  },
  computed: {
    isEven() {
      return this.index % 2 === 0;
    },
  },
};
</script>
<template>
  <div v-if="isEven" class="design dark even">
    <div class="text-container">
      <p class="header show-on-scroll" v-html="content.title" />
      <div class="links show-on-scroll">
        <a
          v-if="content.links.github"
          v-bind:href="content.links.github"
          target="_blank"
        >
          <img
            class="icon"
            src="@/assets/images/icons8-github-30.png"
            alt="github"
          />
        </a>
        <a
          v-if="content.links.projectUrl"
          v-bind:href="content.links.projectUrl"
          target="_blank"
        >
          <img
            class="icon"
            src="@/assets/images/icons8-external-link-30.png"
            alt="external link"
          />
        </a>
      </div>
      <div class="tools-container show-on-scroll">
        <div
          v-bind:key="index"
          v-for="(tool, index) in content.tools"
          class="tool"
        >
          <p>{{ tool }}</p>
        </div>
      </div>
      <div class="description show-on-scroll">
        <p v-html="content.description" />
      </div>
    </div>
    <div class="image-container show-on-scroll">
      <a v-bind:href="content.links.projectUrl" target="_blank">
        <img
          v-bind:src="require(`@/${content.img}`)"
          alt="project screenshot"
        />
      </a>
    </div>
  </div>
  <div v-else class="design dark odd">
    <div class="image-container show-on-scroll">
      <a v-bind:href="content.links.projectUrl" target="_blank">
        <img
          v-bind:src="require(`@/${content.img}`)"
          alt="project screenshot"
        />
      </a>
    </div>
    <div class="text-container">
      <p class="header show-on-scroll" v-html="content.title" />
      <div class="links show-on-scroll">
        <a
          v-if="content.links.github"
          v-bind:href="content.links.github"
          target="_blank"
        >
          <img
            class="icon"
            src="@/assets/images/icons8-github-30.png"
            alt="github"
          />
        </a>
        <a
          v-if="content.links.projectUrl"
          v-bind:href="content.links.projectUrl"
          target="_blank"
        >
          <img
            class="icon"
            src="@/assets/images/icons8-external-link-30.png"
            alt="external link"
          />
        </a>
      </div>
      <div class="tools-container show-on-scroll">
        <div
          v-bind:key="index"
          v-for="(tool, index) in content.tools"
          class="tool"
        >
          <p>{{ tool }}</p>
        </div>
      </div>
      <div class="description show-on-scroll">
        <p v-html="content.description" />
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.design {
  margin: 40px auto 100px auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  @include exo;
  .text-container {
    width: 100%;
    box-sizing: border-box;
    margin: 10px 0;
    .header {
      font-size: 1.1em;
      @include wgt-400;
      color: $charcoal;
      sup {
        color: $highlight;
      }
    }
    .links {
      .icon {
        width: 25px;
        height: auto;
        margin-right: 10px;
        &:hover {
          @include highlight-filter;
          translate: 0px -5px;
        }
      }
    }
    .tools-container {
      width: 100%;
      margin: 5px 0px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      .tool {
        margin-right: 10px;
        border-radius: 5px;
        p {
          color: $highlight;
          @include exo;
          margin: 0px;
          padding: 0px;
        }
      }
    }
    .description {
      line-height: 30px;
      @include p-link;
      @include wgt-300;
      color: $charcoal;
    }
  }
  .image-container {
    width: 100%;
    display: flex;
    margin-top: 0px;
    a {
      width: 95%;
      margin: auto;
      img {
        width: 100%;
        height: auto;
        @include image-hover-light;
        border: 2px solid $charcoal;
        box-shadow: 5px 5px $highlight, 5px 5px 0em 0.1em $charcoal;
      }
    }
  }
  @include lg {
    .text-container {
      width: 45%;
      margin: 0;
    }
    .image-container {
      width: 55%;
      max-width: 550px;
    }
    &.even {
      .text-container {
        padding: 10px 10px 10px 0;
        text-align: left;
        .tools-container {
          justify-content: flex-start;
        }
      }
    }
    &.odd {
      .text-container {
        padding: 10px 0px 10px 10px;
        text-align: right;
        .tools-container {
          justify-content: flex-end;
        }
      }
    }
  }
  &.dark {
    .text-container {
      .header {
        color: $xlight_grey;
      }
      .links {
        .icon {
          @include white-filter;
          &:hover {
            @include highlight-filter;
          }
        }
      }
      .tools-container {
        .tool {
          p {
            color: $highlight;
          }
        }
      }
      .description {
        color: $xlight_grey;
      }
    }
    .image-container {
      img {
        border-radius: 5px;
        @include image-hover-dark;
      }
    }
  }
}
</style>
