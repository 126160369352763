<script>
import SidePanel from "@/Components/SidePanel.vue";
import PortfolioContainer from "@/Components/PortfolioContainer.vue";
import { viewsMixin } from "@/mixins/viewsMixin";

export default {
  name: "PortfolioView",
  mixins: [viewsMixin],
  data() {
    return {
      viewStateId: "PortfolioView",
    };
  },
  components: {
    SidePanel,
    PortfolioContainer,
  },
};
</script>

<template>
  <div id="PortfolioView" class="dark">
    <SidePanel :content="content.sidePanel" />
    <PortfolioContainer :content="content.portfolio" />
  </div>
</template>

<style lang="scss"></style>
