<script>
import { contentMixin } from "@/mixins/contentMixin.js";
import { DomUtility } from "@/utilities/domUtility";

export default {
  name: "SidePanel",
  mixins: [contentMixin],
  mounted() {
    DomUtility.setNavItem(this.content.panelItems[0].id);
  },
  methods: {
    selectNavItem(event, navItemId) {
      DomUtility.setNavItem(navItemId);
    },
  },
};
</script>
<template>
  <div id="SidePanel" v-if="hasContent" class="dark">
    <div class="side-panel-nav">
      <a
        v-bind:id="item.id"
        v-bind:key="item.id"
        v-for="item in content.panelItems"
        @click="($event) => selectNavItem($event, item.id)"
        v-bind:href="item.href"
        class="side-panel-item"
      >
        <div class="indentation" />
        <p v-if="item.label">{{ item.label }}</p>
        <img v-else src="@/assets/images/icons8-home-30.png" alt="home icon" />
      </a>
    </div>
  </div>
</template>
<style lang="scss">
#SidePanel {
  display: none;
  width: 200px;
  height: 100vh;
  position: fixed;
  padding-top: 50px;
  box-sizing: border-box;
  @include exo;
  top: 0;
  left: 0;
  @include lg {
    display: flex;
  }
  .side-panel-nav {
    width: 100%;
    height: max-content;
    margin: auto;
    .side-panel-item {
      width: 90px;
      height: 30px;
      margin: 12px auto;
      border: 0;
      display: flex;
      color: $grey;
      @include wgt-500;
      text-decoration: none;
      .indentation {
        width: 3px;
        height: 100%;
        background-color: $grey;
        border-radius: 5px;
      }
      p,
      img {
        margin: auto 10px;
      }
      p {
        transition: 0.2s;
      }
      img {
        @include grey-filter;
      }
      &:first-child {
        animation: 0.4s ease-out 0s 1 slideInFromLeft;
      }
      &:nth-child(2) {
        animation: 0.6s ease-out 0s 1 slideInFromLeft;
      }
      &:nth-child(3) {
        animation: 0.8s ease-out 0s 1 slideInFromLeft;
      }
      &:last-child {
        animation: 1s ease-out 0s 1 slideInFromLeft;
      }
      &.active {
        color: $charcoal;
        height: 50px;
        img {
          filter: none;
        }
        .indentation {
          background-color: $charcoal;
        }
        &:hover {
          color: $charcoal;
          translate: none;
          img {
            filter: none;
          }
          .indentation {
            background-color: $charcoal;
          }
        }
      }
      &:hover {
        color: $highlight;
        translate: 15px 0px;
        img {
          @include highlight-filter;
        }
        .indentation {
          background-color: $highlight;
        }
      }
    }
  }
  &.dark {
    .side-panel-item {
      color: $xlight_grey;
      .indentation {
        background-color: $light_grey;
      }
      img {
        @include light-grey-filter;
      }
      &.active {
        color: $white;
        .indentation {
          background-color: $white;
        }
        img {
          @include white-filter;
        }
        &:hover {
          color: $white;
          img {
            @include white-filter;
          }
          .indentation {
            background-color: $white;
          }
        }
      }
    }
  }
}
</style>
