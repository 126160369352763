<script>
import DesignItem from "@/Components/DesignItem.vue";
import { contentMixin } from "@/mixins/contentMixin.js";

export default {
  name: "DesignSection",
  mixins: [contentMixin],
  components: {
    DesignItem,
  },
};
</script>
<template>
  <div class="section dark" id="Archive">
    <div class="section-container">
      <div class="design-container show-on-scroll animate-on-mobile">
        <p class="section-header show-on-scroll" v-html="content.header" />
        <p class="design-note show-on-scroll" v-html="content.note" />
        <DesignItem
          v-bind:key="index"
          v-for="(project, index) in content.projects"
          :index="index"
          :content="project"
        />
      </div>
    </div>
  </div>
</template>
<style lang="scss">
#Archive {
  .section-container {
    .design-note {
      line-height: 30px;
      @include wgt-300;
      color: $charcoal;
    }
    .design-container {
      margin: 15px;
      color: $charcoal;
      @include lg {
        margin: auto;
      }
    }
  }
  &.dark {
    .section-container {
      .design-note {
        color: $xlight_grey;
      }
    }
  }
}
</style>
