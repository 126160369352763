<script>
import { contentMixin } from "@/mixins/contentMixin.js";

export default {
  name: "HomeSection",
  mixins: [contentMixin],
};
</script>
<template>
  <div class="section dark" id="Home">
    <div class="home-section-container">
      <div class="header-container">
        <p class="header" v-html="content.header" />
        <p class="subtext" v-html="content.subtext" />
        <div class="last-updated">
          <img
            src="@/assets/images/icons8-info-squared-50.png"
            alt="info icon"
          />
          <span v-html="content.lastUpdate" />
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
#Home {
  min-height: 400px;
  height: fit-content;
  margin: 60px auto;
  .home-section-container {
    margin: auto;
    text-align: right;
    .header-container {
      margin: 10px;
      margin-right: 20px;
      padding-bottom: 50px;
      position: relative;
      animation: 1s ease-out 0s 1 fadeInSlideUp;
      .header,
      .subtext {
        span {
          color: $highlight;
        }
      }
      .header {
        font-size: 1.5em;
        margin: 7px auto;
        color: $charcoal;
        @include bowlby-one-font;
        @include sm {
          font-size: 2em;
        }
        @include md {
          font-size: 2.5em;
        }
        @include lg {
          font-size: 3em;
        }
      }
      .subtext {
        font-size: 1em;
        line-height: 40px;
        color: $subtext;
        margin: 2px auto;
        @include exo;
        @include wgt-300;
        @include md {
          font-size: 1.1em;
        }
      }
      @include sm {
        max-width: 600px;
      }
      .last-updated {
        display: block;
        position: absolute;
        bottom: 0px;
        right: 0px;
        animation: 1s ease-out 0s 1 slideAndTilt;
        @include popover;
        img {
          width: 25px;
          height: 25px;
        }
        @include xl {
          display: none;
        }
        &:hover {
          img {
            @include highlight-filter;
          }
        }
      }
    }
  }
  &.dark {
    .home-section-container {
      .header-container {
        .header,
        .subtext {
          color: $xlight_grey;
        }
        .last-updated {
          img {
            @include white-filter;
          }
          &:hover {
            img {
              @include highlight-filter;
            }
          }
          span {
            background-color: rgba(255, 255, 255, 0.9);
            color: $app_dark_background;
            &:after {
              border-left: solid 10px rgba(255, 255, 255, 0.9);
            }
          }
        }
      }
    }
  }
  @include lg {
    height: auto;
    margin: auto;
  }
}
</style>
