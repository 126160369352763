<script>
import { contentMixin } from "@/mixins/contentMixin.js";
import { DomUtility } from "@/utilities/domUtility";

export default {
  name: "AboutSection",
  mixins: [contentMixin],
  mounted() {
    DomUtility.toggleCollapsible(
      "certificatesLabel",
      "certificatesCollapsible",
      "certificatesWrapper",
      10
    );
  },
  computed: {
    pdfLink() {
      return `DamiLapiteResume.pdf`;
    },
  },
  methods: {
    toggleCollapsible() {
      DomUtility.toggleCollapsible(
        "certificatesLabel",
        "certificatesCollapsible",
        "certificatesWrapper",
        10
      );
    },
  },
};
</script>
<template>
  <div class="section dark" id="About">
    <div class="section-container">
      <div class="about-me-container show-on-scroll animate-on-mobile">
        <p class="section-header show-on-scroll" v-html="content.header" />
        <p class="description show-on-scroll" v-html="content.description" />
        <p
          class="description show-on-scroll"
          v-html="content.toolsDescription"
        />
        <div class="tools-container show-on-scroll">
          <div
            v-bind:key="index"
            v-for="(tool, index) in content.tools"
            class="tool"
          >
            <img src="@/assets/images/icons8-push-pin-48.png" alt="thumbtack" />
            <p>{{ tool }}</p>
          </div>
        </div>
        <a :href="pdfLink" target="_blank" rel="noreferrer" class="link-button">
          <div class="show-on-scroll">
            <p v-html="content.resumeLabel" />
          </div>
        </a>
        <div
          id="certificatesLabel"
          class="expand-label show-on-scroll"
          @click="toggleCollapsible"
        >
          <div class="label-body">
            <p v-html="content.certificatesLabel" />
            <img
              class="icon"
              src="@/assets/images/icons8-expand-arrow-50.png"
              alt="github"
            />
          </div>
          <div class="label-underline" />
        </div>
        <div id="certificatesWrapper" class="certificates-wrapper">
          <div id="certificatesCollapsible" class="certificates-container">
            <div
              v-bind:key="index"
              v-for="(certificate, index) in content.certificates"
            >
              <div class="certificate">
                <div class="time">
                  <p v-html="certificate.year" />
                </div>
                <div class="item">
                  <p class="course" v-html="certificate.course" />
                  <p class="issuer" v-html="certificate.issuer" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
#About {
  height: fit-content;
  .section-container {
    .about-me-container {
      margin: 15px;
      text-align: left;
      color: $charcoal;
      @include lg {
        margin: auto;
      }
      .description {
        line-height: 40px;
        @include p-link;
        @include wgt-300;
      }
      .tools-container {
        width: 100%;
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        .tool {
          margin-right: 10px;
          margin-bottom: 10px;
          padding: 5px;
          color: $charcoal;
          p {
            display: inline;
            padding: 0px 7px;
            margin: 0;
            @include exo;
            @include wgt-300;
          }
          img {
            display: inline;
            width: 20px;
          }
        }
      }
      .expand-label {
        margin-top: 30px;
        @include underline-hover;
        .label-body {
          .icon {
            width: 20px;
            height: auto;
            margin-left: 10px;
            rotate: 180deg;
            @include highlight-filter;
          }
        }
        &.collapsed {
          .icon {
            rotate: 0deg;
          }
        }
      }
      .certificates-wrapper {
        overflow: hidden;
        margin: 10px auto;
        .certificate {
          @include time-item-container;
        }
      }
      a.link-button {
        @include link-button;
      }
    }
  }
  &.dark {
    .section-container {
      .about-me-container {
        color: $xlight_grey;
        .education-work-container {
          .container-header {
            .container-header-item {
              border: 2px solid $highlight;
              border-top: none;
              border-bottom: 2px solid $white;
              .top-border {
                background-color: $highlight;
                border-bottom: 2px solid $highlight;
                border-top: 2px solid $highlight;
              }
              .container-header-text {
                p {
                  color: $highlight;
                }
              }
              &:first-child {
                border-right: none;
                border-radius: 8px 0px 0px 0px;
                .top-border {
                  border-radius: 6px 0px 0px 0px;
                }
              }
              &:last-child {
                border-left: none;
                border-radius: 0px 8px 0px 0px;
                .top-border {
                  border-radius: 0px 6px 0px 0px;
                }
              }
              &.active {
                border-color: $white;
                border-bottom: none;
                &:first-child {
                  border-right: 2px solid $white;
                }
                &:last-child {
                  border-left: 2px solid $white;
                }
                .top-border {
                  background-color: $white;
                  border-color: $white;
                }
                .container-header-text {
                  p {
                    color: $white;
                  }
                }
              }
              &:not(.active):hover {
                .container-header-text {
                  p {
                    color: $white;
                  }
                }
              }
            }
          }
          .container-body {
            border: 2px solid $white;
            border-top: 0;
            border-radius: 0px 0px 6px 6px;
            .container-body-text {
              .degree,
              .school {
                color: $xlight_grey;
              }
              .dates {
                color: $xlight_grey;
              }
            }
          }
        }
        .tools-container {
          .tool {
            color: $xlight_grey;
            box-shadow: none;
            border: none;
          }
        }
      }
    }
  }
}
</style>
