import { mapGetters } from "vuex";
import { DomUtility } from "@/utilities/domUtility";

export const viewsMixin = {
  computed: {
    ...mapGetters(["content", "darkMode"]),
  },
  mounted() {
    if (DomUtility.isMobileMenuActive) {
      DomUtility.hideMobileMenu();
    }
    if (this.darkMode && !DomUtility.hasClass(this.viewStateId, "dark")) {
      DomUtility.darkModeOn();
    } else if (
      !this.darkMode &&
      DomUtility.hasClass(this.viewStateId, "dark")
    ) {
      DomUtility.darkModeOff();
    }
  },
};
